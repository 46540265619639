import React from 'react';
import * as RakutenActions from '../../Actions/Rakuten';
import * as AppActions from '../../AppActions';

const pageSizeOptions = [10, 30, 50];
const sortOptions = [
  {name: '價錢高到低', value: '-price'},
  {name: '價錢低到高', value: 'price'},
];

async function fetchMany(
  {sort, paging, keyword, startDate, endDate, itemStatus},
  onSuccess,
  onFailure,
) {
  console.log('fetchMany', startDate, endDate);
  try {
    AppActions.setLoading(true);
    const {total, results} = await RakutenActions.fetchProductList(
      {
        startDate,
        endDate,
        /*
        update_time_from: Math.floor(new Date(startDate).getTime() / 1000),
        update_time_to: Math.floor(new Date(endDate).getTime() / 1000),
        item_status: [itemStatus],
      */
      },
      paging,
    );
    onSuccess({total, results});
  } catch (ex) {
    console.warn('API error', ex);
    onFailure();
  } finally {
    AppActions.setLoading(false);
  }
}

const makeColumns = () => [
  {
    title: 'SKU',
    key: 'baseSku',
    dataIndex: 'baseSku',
    width: 150,
    fixed: 'left',
  },
  {
    title: 'image',
    key: 'image',
    width: 200,
    render: (_, item) => {
      return (
        <img
          src={
            item.productListedShops[0]?.images &&
            item.productListedShops[0]?.images[0]?.url
          }
          style={{width: 180, height: 120, objectFit: 'contain'}}
          alt="product snapshot"
        />
      );
    },
  },
  {
    title: 'title',
    key: 'title',
    width: 500,
    render: (_, item) => {
      return <div>{item.productListedShops[0]?.title?.zh_TW}</div>;
    },
  },
  {
    title: 'brand',
    key: 'brand',
    width: 100,
    render: (_, item) => {
      return <div>{item.productListedShops[0]?.brand}</div>;
    },
  },
];

export {pageSizeOptions, sortOptions, fetchMany, makeColumns};
