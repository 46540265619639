import {getOutlet} from 'reconnect.js';
import Config from '../../data.json';
import {req} from '../Utils/ApiUtils';

const UserOutlet = getOutlet('user');

async function fetchProductList(query, paging) {
  const {startDate, endDate} = query;
  const resp = await req(
    `${Config.apiHost}/rakuten/list/product?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        query: {
          shopKey: {
            marketplaceIdentifier: 'tw',
            shopUrl: 'ya-kuang',
          },
          updatedAfter: startDate + 'T00:00:00.000Z',
          updatedBefore: endDate + 'T00:00:00.000Z',
        },
        detailLevel: 'Product',
        pageIndex: paging.offset / paging.limit,
        maxResultsPerPage: paging.limit,
        sortOrder: 'DESC',
      },
    },
  );

  if (resp.errors) {
    alert('API fail, 請重設搜尋條件');
    throw new Error(resp);
  }

  const {totalCount, products} = resp;

  return {
    total: totalCount,
    results: products,
  };
}

async function fetchCategoryList(query, paging) {
  /*
  const {
    update_time_from = 0,
    update_time_to = 0,
    item_status = ['NORMAL'],
  } = query;
  */
  const resp = await req(
    `${Config.apiHost}/rakuten/category/list?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        // offset: paging.offset,
        // page_size: paging.limit,
        // update_time_from,
        // update_time_to,
        // item_status,
      },
    },
  );

  const {shopCategories} = resp;

  return {
    total: shopCategories.length,
    results: shopCategories.slice(paging.offset, paging.offset + paging.limit),
  };
}

async function fetchConfiguration() {
  return await req(
    `${Config.apiHost}/rakuten/configuration?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
    },
  );
}

async function createProduct(data) {
  return req(
    `${Config.apiHost}/rakuten/product/add?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {products: [data]},
    },
  );
}

async function getOperation(id) {
  return req(
    `${Config.apiHost}/rakuten/get/operation?token=${
      UserOutlet.getValue().token
    }&operation_id=${id}`,
    {
      method: 'POST',
    },
  );
}

export {
  fetchProductList,
  fetchCategoryList,
  fetchConfiguration,
  createProduct,
  getOperation,
};
